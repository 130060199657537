import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Service from '../components/services/Service';

import Mechanic from '../svg/mechanic.svg';

const GravureMecanique = props => {
  const title = 'Gravure Mécanique';
  const services = ['Plaques', 'PVC', 'Aluminium'];
  return (
    <Layout>
      <SEO title={title} description="Marougrav réalise vos souhaits de gravure mécanique sur plaque ou sur aluminium" keywords={['gravure']} />
      <Service title={title} services={services} images={props.data.allFile.edges} color="#007A9B">
        <Mechanic />
      </Service>
    </Layout>
  );
};

export const query = graphql`
  {
    allFile(filter: {extension: {eq: "jpg"}, relativeDirectory: {eq: "meca"}}) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

export default GravureMecanique;


